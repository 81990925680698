import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class PageTitleService {
    private translate = inject(TranslateService);
    private title = inject(Title);

    getDefaultPageTitle(): string {
        return this.translate.instant('SHARED.PAGE_TITLE.DEFAULT');
    }

    buildPageTitle(title: string | null | undefined): string {
        return title
            ? `${
                  this.translate.instant(title) as string
              } | ${this.getDefaultPageTitle()}`
            : this.getDefaultPageTitle();
    }

    setPageTitle(title: string | null | undefined): void {
        this.title.setTitle(this.buildPageTitle(title));
    }
}
